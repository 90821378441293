<template lang="html">
  <div class="w-full relative mt-5" style="border:2px solid transparent" :class="isEventStyle ? 'smcom-blink' : ''">
    <img :src="link" alt="" class="select-none" />
  </div>
</template>

<script>
import { requestApi } from "@/utils/api"

export default {
  props: {
    fileGuid: String,
    detectCount: Number,
  },
  data () {
    return {
      link: '',
      isEventStyle: false
    }
  },
  created () {
    this.getImageData()

    // 이너트론 요청... 이벤트에 깜박이는것 필요 없음(20221018)
    // if(this.detectCount > 0) this.isEventStyle = true;
    // else this.isEventStyle = false
  },
  methods: {
    async getImageData () {
      let reqObj = {}
      reqObj.target = `/files/img/${this.fileGuid}`;
      reqObj.method = "get";
      reqObj.params = {};
      reqObj.data = {};

      reqObj.headers = { "accept":"application/json" };

      let result = await requestApi(reqObj);
      this.link = result.msg.retData
    },
  },
  watch: {
    'fileGuid': function () {
      this.getImageData()
    },
    // 이너트론 요청... 이벤트에 깜박이는것 필요 없음(20221018)
    // 'detectCount': function () {
    //   if(this.detectCount > 0) this.isEventStyle = true;
    //   else this.isEventStyle = false
    // }
  }
}
</script>